import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap'

require("trix")
require("@rails/actiontext")
require("jquery")
require("@nathanvda/cocoon")

require("packs/stopwatch")
require('packs/circle-progress')
require('packs/auto-tables')

import 'select2'
import 'select2/dist/css/select2.css'
require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")                   // globally assign select2 fn to $ element

// var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
// var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
//   return new bootstrap.Tooltip(tooltipTriggerEl)
// })
window.jQuery = $;
window.$ = $;

$(document).on('turbolinks:load', function() {
  $('[data-toggle="tooltip"]').tooltip({
    delay: {show: 0, hide: 500}
  });
});


// import 'select2/dist/css/select2.css';  // optional if you have css loader
$.fn.select2.defaults.set("theme", "bootstrap");
$(() => {
  $('.select2-enable').select2();
});


$(document).ready(function () {
  // Funktion zum Hinzufügen des Event-Handlers für die 'select_lot_nr_prefill' Elemente
  function addChangeEventHandler() {
    $('.select_lot_nr_prefill').each(function () {
      if ($(this).hasClass("parsed")) return;
      $(this).addClass('parsed');

      $(this).on('change', function (ev) {
        $.ajax({
          type: 'GET',
          url: '/products/set_lot_nr_prefix',
          dataType: "json",
          data: { product_id: $(this).val() },
          success: function (data) {
            if (data['prefix'] != null) {
              $(".lot_nr_field").attr("placeholder", `${data['prefix']}/`);
            } else {
              $(".lot_nr_field").attr("placeholder", "");
            }
          },
          error: function (request, status, error) {
            alert(request.responseText);
          }
        });
      });
    });
  }

  // Initiales Hinzufügen des Event-Handlers
  addChangeEventHandler();

  // Erstellung eines MutationObservers
  const observer = new MutationObserver(function (mutationsList, observer) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
        addChangeEventHandler();
      }
    }
  });

  // Konfiguration des Observers
  const config = { childList: true, subtree: true };

  // Starten des Observers auf dem gesamten Dokument
  observer.observe(document.body, config);
});



$(document).on('turbolinks:load', function () {
  $('.select2-enable').select2();

  $(".modal").on("hidden.bs.modal", function () {
    if (this.id !== "filters-modal") {
      $(this).html("");
    }
  });

  // prevent mouse wheel scrolling in number input fields
  // does not work inside of modals
  $('form').on('focus', 'input[type=number]', function (e) {
    $(this).on('wheel.disableScroll', function (e) {
      e.preventDefault()
    })
  });
  $('form').on('blur', 'input[type=number]', function (e) {
    $(this).off('wheel.disableScroll')
  });
  document.addEventListener("wheel", function(event) {
    if(document.activeElement.type === "number") {
      document.activeElement.blur();
    }
  });
  // doesn´t work if bound to modal shown event
  // $(".modal").on("shown.bs.modal", function () {
  //   $('form').on('focus', 'input[type=number]', function (e) {
  //     $(this).on('wheel.disableScroll', function (e) {
  //       e.preventDefault()
  //     })
  //   });
  //   $('form').on('blur', 'input[type=number]', function (e) {
  //     $(this).off('wheel.disableScroll')
  //   });
  // });
});

$(document).on('select2:open', () => {
  document.querySelector('.select2-search__field').focus();
});


$(document).on('turbolinks:load', function () {
  // Search all columns
  $('#search_table').keyup(function(){
    // Search Text
    var search = $(this).val();

    // Hide all table tbody rows
    $('table tbody tr').hide();

    // Count total search result
    var len = $('table tbody tr:not(.notfound) td:contains("'+search+'")').length;

    if(len > 0){
      // Searching text in columns and show match row
      $('table tbody tr:not(.notfound) td:contains("'+search+'")').each(function(){
        $(this).closest('tr').show();
      });
    }else{
      $('.notfound').show();
    }
  });
});

// Case-insensitive searching (Note - remove the below script for Case sensitive search )
$.expr[":"].contains = $.expr.createPseudo(function(arg) {
   return function( elem ) {
     return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
   };
});
